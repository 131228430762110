import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import Settings from "../../../components/quiz/settings"
import ToggleList from "../../../components/quiz/toggle-list"
import Toggle from "../../../components/quiz/toggle"
import Button from '@material-ui/core/Button';
import queryString from 'query-string';

const Furigana = require("gem-furigana").Furigana
const counters = require("../../../components/japanese/data/counters.json")

class Counter extends React.Component  {
  constructor(props) {
    super(props);
    this.counter = "";
    this.number = 0;
    this.lastcounter = "";
    this.answer = "";
    this.correct = 0;
    this.incorrect = 0;
    this.opts = {};
    const search = typeof window !== 'undefined' ? window.location.search : '';
    let params = queryString.parse(search);
    counters.forEach(function(x) {
      this.opts[x.meaning[1]] = params[x.meaning[1].replace(" ", "-")] === "true"
    }, this)
    this.newCounter = this.newCounter.bind(this);
    this.counterToggle = this.counterToggle.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.previousAnswers = {}
    for(var c in counters) {
      this.previousAnswers[counters[c].counter] = [0,1,2,3,4,5,6,7,8,9]
    }
    this.previousAnswers["歳[さい]"].push(20)

    this.state = {
        loaded: false,
        counter: this.counter,
        number: this.number,
        question: "",
        lastAnswer: "",
        status: "",
        wait: false,
        opts: this.opts,
        correct: this.correct,
        incorrect: this.incorrect,
    };
  }

  newCounter() {
    var counter;
    var number;
    var activeCounters = counters.filter(x => this.state.opts[x.meaning[1]]);
    if (activeCounters.length === 0) {activeCounters = counters}
    do {
      counter = activeCounters[Math.floor(Math.random() * activeCounters.length)]
      if(this.previousAnswers[counter.counter].length <= 2) {
        if (counter.counter === "歳[さい]") {
          this.previousAnswers[counter.counter] = [0,1,2,3,4,5,6,7,8,9,20]
        } else {
          this.previousAnswers[counter.counter] = [0,1,2,3,4,5,6,7,8,9]
        }
      }
      number = this.previousAnswers[counter.counter][Math.floor(Math.random() * this.previousAnswers[counter.counter].length)]
    } while ((this.lastcounter === counter && activeCounters.length > 1) || this.lastnumber === number) // Prevents same counter from appearing twice in a row
    this.number = number;
    this.lastnumber = number
    this.counter = counter;
    this.lastcounter = counter;

    this.setState({ number: number, counter: counter, lastcounter: counter, wait: false, status: "" });
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    var meaning = (this.number === 0) ? this.counter.meaning[0] : this.counter.meaning[1];
    if (this.number < 10) {
      this.question = (this.number + 1).toString() + " " + meaning;
    } else {
      this.question = (this.number).toString() + " " + meaning;
    }
    this.setState({ question: this.question });
  }

  updateAnswer() {
    if (this.number < 10) {
      this.answer = this.counter.readings[this.number];
    } else {
      this.answer = this.counter.extra_readings[this.number];
    }
  }

  checkAnswer(userAnswer) {
    var status = "";
    if(this.answer === userAnswer || (Array.isArray(this.answer) && this.answer.includes(userAnswer))) {
      status = "correct";
      this.correct += 1;
      this.previousAnswers[this.counter.counter].splice(this.previousAnswers[this.counter.counter].indexOf(this.number), 1);
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer;
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  numInactive() {
    // Returns the number of this inactive toggles
    return Object.keys(this.opts).filter(k => !this.opts[k]).length;
  }

  counterToggle = e => {
    this.opts[e.target.name] = !this.opts[e.target.name]
    this.setState({ opts: this.opts });
    this.newCounter()
  }

  toggleAll = e => {
    let inactiveToggles = this.numInactive();
    Object.keys(this.opts).forEach(c => this.opts[c] = inactiveToggles !== 0);
    this.setState({ opts: this.opts });
    this.newCounter();
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newCounter()
      this.setState({ loaded: true });
    }
  }

  render() {
    var counterToggles = counters.map((p, i) => (
      <Toggle key={p.meaning[1]} id={p.meaning[1] + "-setting"} label={`～${new Furigana(p.counter).Expression} (${p.meaning[1]})`} name={p.meaning[1]} onToggle={this.counterToggle} checked={this.state.opts[p.meaning[1]]} />
    ));

    return (
      <QuizMaster
        title="Japanese Counters"
        description={`Interactive Study tool for learning Japanese counters. ${counters.length} different counters currently available to study with more to come.`}
        question={this.state.question}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newCounter}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <>
            <Settings>
              <ToggleList>{counterToggles}</ToggleList>
            </Settings>
            <Button variant="contained" style={{margin:"10px"}} onClick={this.toggleAll}>
              {this.numInactive() === 0 ? "Select None" : "Select All"}
            </Button>
          </>
        }
      >  
      </QuizMaster>
    );
  }
}

export default Counter
